import { graphql, StaticQuery } from 'gatsby'
import React from 'react'

import BlockContent from '../components/block-content'
import Person from '../components/person'
import Seo from '../components/seo'
import { responsiveTitle1 } from '../components/typography.module.css'
import Layout from '../containers/layout'

const AboutPage = ({ data, errors }) => {
  const {
    title,
    _rawBody
  } = data && data.page
  const person = data && data.person

  return (
    <Layout>
      <Seo title={title} />
      <h1 className={responsiveTitle1}>
        Hey <span role='img' title='Wave emoji'>👋</span> I'm {person.name}
      </h1>
      <BlockContent blocks={_rawBody || []} />
      <Person person={person} />
    </Layout>
  )
}

export default function AboutPageQuery () {
  return (
    <StaticQuery
      query={graphql`
      query AboutPageQuery {
        page: sanityPage(_id: { regex: "/(drafts.|)about/" }) {
          id
          title
          _rawBody
        }
        person: sanityPerson(name: {regex: "/Harry/"}) {
          id
          name
          twitter
          linkedIn
          _rawBio
          image {
            asset {
              _id
              _rawMetadata
            }
          }
        }
      }
    `}
      render={data => <AboutPage data={data} />}
    />
  )
}
