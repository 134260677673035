import React from 'react'

import OutboundLink from './block-content/outboundLink'
import { FaLinkedin } from 'react-icons/fa'

const LinkedInBadge = ({ linkedIn, name }) => {
  return (
    <OutboundLink
      href={`https://www.linkedin.com/in/${linkedIn}/`}
      title={`Connect with ${name} on LinkedIn`}
    >
      <FaLinkedin size='1.5em' />
    </OutboundLink>
  )
}

export default LinkedInBadge
