import React from 'react'
import { Helmet } from 'react-helmet'

import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import googleSpecialistBadge from './../img/google-specialist-badge.png'
import OutboundLink from './block-content/outboundLink'
import BlockText from './block-text'
// import DevToBadge from './dev.to-badge'
import styles from './person.module.css'
import TwitterBadge from './twitter-badge'
import LinkedInBadge from './linkedIn-badge'

function Person ({ person }) {
  const { name, image, _rawBio, twitter, devto, linkedIn } = person
  const imageUrl = imageUrlFor(buildImageObj(image))
    .width(600)
    .height(600)
    .fit('crop')
    .url()

  return (
    <div className={styles.root}>
      <Helmet>
        <link rel='preload' as='image' href={imageUrl} importance='high' />
      </Helmet>
      <div className={styles.profileCard}>
        {image && image.asset && (
          <div
            className={styles.profileImageWrapper} style={{
              paddingTop: `min(${image.asset._rawMetadata.dimensions.aspectRatio} * 100%, 300px)`
            }}
          >
            <img
              className={styles.profileImage}
              src={imageUrl}
              alt={name ? `${name}'s profile image` : 'Profile image'}
            />
          </div>
        )}
        <div className={styles.badges}>
          {linkedIn && <LinkedInBadge linkedIn={linkedIn} name={name} />}
          {/* {devto && <DevToBadge devto={devto} name={name} />} */}
          {name.toLowerCase().match(/harry/) &&
            <OutboundLink
              title='View my Mobile Web Specialist certification by Google Developers'
              href='https://www.credential.net/aedcb6b3-3713-4668-bfac-6fe284401e2d'
            >
              <img
                width={25}
                height={25}
                loading='lazy'
                src={googleSpecialistBadge}
                alt='Mobile Web Specialist certification by Google Developers'
              />
            </OutboundLink>}
          {twitter && <TwitterBadge twitter={twitter} name={name} />}
        </div>
        {_rawBio && (
          <div className={styles.bio}>
            <BlockText blocks={_rawBio} />
          </div>
        )}
      </div>
    </div>
  )
}

export default Person
