import React from 'react'

import OutboundLink from './block-content/outboundLink'

const TwitterBadge = ({ twitter, name }) => {
  return (
    <OutboundLink
      href={`https://twitter.com/${twitter}?ref_src=twsrc%5Etfw`}
      className='twitter-follow-button'
      data-show-count='false'
      data-show-screen-name='false'
      target='_blank'
    >
      Follow {name} on twitter
    </OutboundLink>
  )
}

export default TwitterBadge
